<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-text="$t('onboarding.boardMemberForm.boardMember')"/>
    </v-card-title>
    <v-form ref="form" v-model="valid" :disabled="loading" lazy-validation>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="boardMember.first_name"
                filled
                required
                :rules="namesRules"
                :label="$t('onboarding.boardMemberForm.firstName')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="boardMember.last_name"
                filled
                required
                :rules="namesRules"
                :label="$t('onboarding.boardMemberForm.lastName')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="boardMember.national_id"
                filled
                required
                :rules="numberRules"
                :label="$t('onboarding.boardMemberForm.nationalIdNumber')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <datepicker
                v-model="boardMember.birthdate"
                filled
                :label="$t('onboarding.boardMemberForm.birthdate')"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="boardMember.place_of_birth"
                filled
                :label="$t('onboarding.boardMemberForm.placeOfBirth')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <BaseMultiselect
                v-model="boardMember.country"
                filled
                :items="countries"
                :rules="[(v) => !!v || $t('errors.fieldRequired')]"
                :label="$t('onboarding.boardMemberForm.documentIssuingCountry')"
                :multiple="false"
                multi-select-data-type="country"
                required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <template v-if="boardMember.files_detailed && boardMember.files_detailed.length">
                <div v-text="$t('onboarding.companyForm.existingFiles')"/>
                <ul class="mb-4">
                  <li v-for="file in boardMember.files_detailed" :key="file.id">
                    <v-btn
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                    <v-btn x-small class="mr-2 my-1 py-4" text @click="boardMember.files_detailed = boardMember.files_detailed.filter(x => x !== file)">
                      <v-icon left>mdi-close</v-icon>
                      {{ $t('common.remove') }}
                    </v-btn>
                  </li>
                </ul>
              </template>
              <v-file-input
                v-model="files"
                filled
                multiple
                small-chips
                truncate-length="15"
                :rules="copyOfTheDocumentRules"
                :label="$t('onboarding.boardMemberForm.uploadCopyOfTheDocument')"
                required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              {{ $t('onboarding.companyForm.politicallyExposedPerson') }}
              <v-radio-group
                v-model="boardMember.is_exposed"
                row
                dense
                :rules="[(v) => v != null || $t('errors.fieldRequired')]"
              >
                <v-radio :label="$t('common.yes')" :value="true"/>
                <v-radio :label="$t('common.no')" :value="false"/>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              {{ $t('onboarding.boardMemberForm.canRepresent') }}
              <v-radio-group
                v-model="boardMember.can_represent"
                row
                dense
                required
                :rules="[(v) => v != null || $t('errors.fieldRequired')]"
              >
                <v-radio :label="$t('onboarding.boardMemberForm.yes')" :value="true"/>
                <v-radio :label="$t('onboarding.boardMemberForm.no')" :value="false"/>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-row v-if="boardMember.can_represent === true">
              <v-col cols="12">
                <template v-if="boardMember.registry_extract_detailed.length">
                  <div v-text="$t('onboarding.companyForm.existingFiles')"/>
                  <ul class="mb-4">
                    <li v-for="file in boardMember.registry_extract_detailed" :key="file.id">
                      <v-btn
                        color="lightgrey" :href="file.file" target="_blank" download
                        x-small class="mr-2 my-1 py-4"
                      >
                        <v-icon left>mdi-attachment</v-icon>
                        {{ toFilename(file) }}
                      </v-btn>
                      <v-btn x-small class="mr-2 my-1 py-4" text @click="boardMember.registry_extract_detailed = boardMember.registry_extract_detailed.filter(x => x !== file)">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('common.remove') }}
                      </v-btn>
                    </li>
                  </ul>
                </template>
                <v-file-input
                  v-model="filesRegistryExtract"
                  filled
                  multiple
                  small-chips
                  truncate-length="15"
                  :rules="[filesRegistryExtract.length > 0 || boardMember.registry_extract_detailed.length > 0]"
                  :label="$t('onboarding.boardMemberForm.uploadRegistryExtract')"
                />
              </v-col>
            </v-row>
            <v-row v-if="boardMember.can_represent === false">
              <v-col cols="12">
                <template v-if="boardMember.files_poa_detailed.length">
                  <div v-text="$t('onboarding.companyForm.existingFiles')"/>
                  <ul class="mb-4">
                    <li v-for="file in boardMember.files_poa_detailed" :key="file.id">
                      <v-btn
                        color="lightgrey" :href="file.file" target="_blank" download
                        x-small class="mr-2 my-1 py-4"
                      >
                        <v-icon left>mdi-attachment</v-icon>
                        {{ toFilename(file) }}
                      </v-btn>
                      <v-btn x-small class="mr-2 my-1 py-4" text @click="boardMember.files_poa_detailed = boardMember.files_poa_detailed.filter(x => x !== file)">
                        <v-icon left>mdi-close</v-icon>
                        {{ $t('common.remove') }}
                      </v-btn>
                    </li>
                  </ul>
                </template>
                <v-file-input
                  v-model="filesPOA"
                  filled
                  multiple
                  small-chips
                  truncate-length="15"
                  :label="$t('onboarding.boardMemberForm.uploadPoa')"
                />
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-container>
      </v-card-text>
      <v-card-actions class="mx-5">
        <v-spacer/>
        <v-btn class="ma-3 pa-5" text :disabled="loading" @click="close()" v-text="$t('common.close')"/>
        <v-btn class="my-3 pa-5" dark :loading="loading" @click="submit()" v-text="$t('common.save')"/>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: "BoardMemberForm",
  props: {
    items: { type: Array, required: true },
    member: { type: Object, required: false, default: () => null},
    countries: { type: Array, required: true },
  },
  emits: ["success", "close"],
  data() {
    return {
      valid: true,
      loading: false,
      namesRules: [
        (v) => !!v || this.$t('errors.fieldRequired'),
        (v) => new RegExp("^([^0-9]*)$").test(v) || this.$t('errors.onlyLetters'),
      ],
      numberRules: [
        (v) => !!v || this.$t('errors.fieldRequired'),
        (v) => (!!this.member || !this.items.filter(item => item.national_id == v).length) || "This board member already exists",
      ],
      copyOfTheDocumentRules: [
        (v) => (!!this.member || !!v.length) || this.$t('errors.fieldRequired'),
      ],
      boardMember: {
        first_name: "",
        last_name: "",
        national_id: "",
        birthdate: null,
        place_of_birth: null,
        country: "",
        can_represent: null,
        files:                  [],
        files_poa:              [],
        files_detailed:         [],
        files_poa_detailed:     [],
        registry_extract:       [],
        registry_extract_detailed: [],
      },
      files: [],
      filesPOA: [],
      filesRegistryExtract: [],
    }
  },
  created() {
    if (this.member) {
      this.boardMember = { ...this.member }
    }
  },
  methods: {
    toFilename(file) {
      return file.file.split('/').pop().split('?')[0]
    },
    close() {
      this.boardMember.files_detailed = []
      this.boardMember.files_poa_detailed = []
      this.boardMember.registry_extract_detailed = []
      this.$refs.form.reset()
      this.$emit("close")
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true

        const promise = Promise.all(this.files.map(file => {
          const formData = new FormData()
          formData.append('file', file, file.name)
          return this.$http.post('/company_board_member_files/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })).then((responses) => {
          this.boardMember.files_detailed = [ ...this.boardMember.files_detailed, ...responses.map(response => response.data) ]
        })
        const promisePOA = Promise.all(this.filesPOA.map(file => {
          const formData = new FormData()
          formData.append('file', file, file.name)
          return this.$http.post('/company_board_member_poa_files/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })).then((responses) => {
          this.boardMember.files_poa_detailed = [ ...this.boardMember.files_poa_detailed, ...responses.map(response => response.data) ]
        })
        const promiseRegistryExtract = Promise.all(this.filesRegistryExtract.map(file => {
          const formData = new FormData()
          formData.append('file', file, file.name)
          return this.$http.post('/company_board_member_registry_extract_files/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        })).then((responses) => {
          this.boardMember.registry_extract_detailed = [ ...this.boardMember.registry_extract_detailed, ...responses.map(response => response.data) ]
        })

        Promise.all([promise, promisePOA, promiseRegistryExtract])
          .then(() => {
            this.loading = false
            this.dialog = false

            if (this.boardMember.birthdate === '') {
              delete this.boardMember.birthdate
            }

            if (this.boardMember.place_of_birth === '') {
              delete this.boardMember.place_of_birth
            }

            this.$emit("success", {...this.boardMember}, this.member)
            this.boardMember.files_detailed = []
            this.boardMember.files_poa_detailed = []
            this.boardMember.registry_extract_detailed = []
            this.$refs.form.reset()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
  },
})
</script>
