<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <BaseMultiselect
            v-model="company.country"
            filled
            :items="registrationHints.country"
            :loading="loadingRegistrationHints"
            :rules="[(v) => !!v || $t('errors.fieldRequired')]"
            :label="$t('onboarding.companyForm.countryOfTaxResidency')"
            :multiple="false"
            multi-select-data-type="country"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="company.name"
            filled
            :rules="[(v) => !!v || $t('errors.fieldRequired')]"
            :label="$t('onboarding.companyForm.companyName')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="company.registration_number"
            filled
            maxlength="25"
            :rules="[(v) => !!v || $t('errors.fieldRequired')]"
            :label="$t('onboarding.companyForm.registrationNumber')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div v-text="$t('onboarding.companyForm.copyOfCommercialRegistryExtract') + ':'"/>
          <ul>
            <v-col v-if="company.registry_extract_files.length > 0">
              <li v-for="file in company.registry_extract_files" :key="file.id">
                <div class="text-no-wrap">
                  <v-btn
                    color="lightgrey" :href="file.file" target="_blank" download
                    x-small class="mr-2 my-1 py-4"
                  >
                    <v-icon left>mdi-attachment</v-icon>
                    {{ toFilename(file) }}
                  </v-btn>
                  <v-btn icon @click="deleteRegistryExtractFile(file)">
                    <v-icon left>mdi-close</v-icon>
                  </v-btn>
                </div>
              </li>
            </v-col>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-file-input
            v-model="registryExtractFiles"
            :rules="[(registryExtractFiles.length > 0 || company.registry_extract_files.length > 0) || $t('errors.fieldRequired')]"
            filled
            multiple
            small-chips
            truncate-length="15"
            :label="$t('onboarding.companyForm.copyOfCommercialRegistryExtract')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          {{ $t('onboarding.companyForm.registryExtractHasRightOfRepresentation') }}
          <v-radio-group
            v-model="includesRightOfRepresentation"
            row
            dense
            :rules="[(v) => v != null || $t('errors.fieldRequired')]"
          >
            <v-radio :label="$t('common.yes')" :value="true"/>
            <v-radio :label="$t('common.no')" :value="false"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="includesRightOfRepresentation === false || company.right_of_representation_files.length > 0" cols="12">
          <v-col v-if="company.right_of_representation_files.length > 0" cols="6">
            <div v-text="$t('onboarding.companyForm.companyRightOfRepresentation') + ':'"/>
            <ul>
              <v-col>
                <li v-for="file in company.right_of_representation_files" :key="file.id">
                  <div class="text-no-wrap">
                    <v-btn
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                    <v-btn icon @click="deleteCompanyRightOfRepresentationFile(file)">
                      <v-icon left>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </li>
              </v-col>
            </ul>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="rightOfRepresentationFiles"
              :rules="[(rightOfRepresentationFiles.length > 0 || company.right_of_representation_files.length > 0) || $t('errors.fieldRequired')]"
              filled
              multiple
              small-chips
              truncate-length="15"
              :label="$t('onboarding.companyForm.companyRightOfRepresentation')"
            />
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="company.email"
            filled
            :rules="emailRules"
            :label="$t('onboarding.companyForm.email')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <BasePhonepicker
            v-model="company.phone"
            :rules="[(v) => !!v || $t('errors.fieldRequired')]"
            :country="company.country"
            :label="$t('onboarding.companyForm.phone')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="company.address"
            filled
            :rules="[(v) => !!v || $t('errors.fieldRequired')]"
            :label="$t('onboarding.companyForm.address')"
          />
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <v-select
            v-model="company.activity"
            filled
            :items="economicActivities"
            :loading="loadingRegistrationHints"
            item-text="name"
            item-value="code"
            :rules="[(v) => !!v || $t('errors.fieldRequired')]"
            :label="$t('onboarding.companyForm.fieldOfEconomicalActivity')"
          />
        </v-col>
      </v-row>

      <v-card class="mt-6 mb-6">
        <v-card-title>{{ $t('onboarding.companyForm.boardMembers') }}</v-card-title>
        <v-card-text>
          <div v-if="errors.board_members" class="red--text">
            {{ errors.board_members }}
          </div>
          <v-data-table
            :headers="boardMembersHeaders"
            :items="company.board_members"
            :items-per-page="-1"
            :no-data-text="$t('onboarding.companyForm.noBoardMembers')"
            hide-default-footer
          >
            <template #[`item.country`]="{ item }">
              <BaseCountry :country="item.country "/>
            </template>
            <template #[`item.can_represent`]="{ item }">
              {{ item.can_represent ? $t('onboarding.companyForm.yes') : $t('onboarding.companyForm.no') }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-btn icon @click="deleteBoardMember(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-dialog v-model="item.boardMembersPopUpEdit" persistent max-width="600px"
                        @keydown.esc="item.boardMembersPopUpEdit = false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <BoardMemberForm
                  v-if="item.boardMembersPopUpEdit"
                  :items="company.board_members"
                  :member="item"
                  :countries="registrationHints.country"
                  @close="item.boardMembersPopUpEdit = false"
                  @success="updateBoardMember"
                />
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-dialog v-model="boardMembersPopUp" persistent max-width="600px"
                    @keydown.esc="boardMembersPopUp = false"
          >
            <template #activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" v-text="$t('common.add')"/>
            </template>
            <BoardMemberForm
              :items="company.board_members"
              :countries="registrationHints.country"
              @close="boardMembersPopUp = false"
              @success="addBoardMember"
            />
          </v-dialog>
        </v-card-actions>
      </v-card>

      <v-card class="mt-6 mb-6">
        <v-card-title>{{ $t('onboarding.companyForm.directShareholders') }}</v-card-title>
        <v-card-text>
          <div v-if="errors.shareholders" class="red--text" v-text="$t('onboarding.companyForm.missingShareholderInfo')"/>
          <v-data-table
            :headers="shareholdersHeaders"
            :items="company.shareholders"
            :items-per-page="-1"
            :no-data-text="$t('onboarding.companyForm.noShareholders')"
            hide-default-footer
          >
            <template #[`item.country`]="{ item }">
              <BaseCountry :country="item.country "/>
            </template>
            <template #[`item.name`]="{ item }">
              <span v-if="item.company_name">{{ item.company_name }}</span>
              <span v-else>{{ item.first_name }} {{ item.last_name }}</span>
            </template>

            <template #[`item.idCode`]="{ item }">
              <span v-if="item.company_name">{{ item.registration_number }}</span>
              <span v-else>{{ item.national_id }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn icon @click="deleteShareholder(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-dialog v-model="item.shareholdersPopUpEdit" persistent max-width="600px"
                        @keydown.esc="item.shareholdersPopUpEdit = false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <ShareholderForm
                  v-if="item.shareholdersPopUpEdit"
                  :items="company.shareholders"
                  :member="item"
                  :countries="registrationHints.country"
                  @close="item.shareholdersPopUpEdit = false"
                  @success="updateShareholder"
                />
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-dialog v-model="shareholdersPopUp" persistent max-width="600px"
                    @keydown.esc="shareholdersPopUp = false"
          >
            <template #activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" v-text="$t('common.add')"/>
            </template>
            <ShareholderForm
              :items="company.shareholders"
              :countries="registrationHints.country"
              @close="shareholdersPopUp = false"
              @success="addShareholder"
            />
          </v-dialog>
        </v-card-actions>
      </v-card>

      <v-checkbox
        v-model="skipBeneficiaries"
        :label="$t('onboarding.companyForm.untickIfThereIsOtherActualBeneficiaries')"
      />

      <v-card v-if="!skipBeneficiaries" class="mt-6 mb-6">
        <v-card-title>
          {{ $t('onboarding.companyForm.actualBeneficiaries') }}
          <v-tooltip slot="append" bottom max-width="400">
            <template #activator="{ on }">
              <v-icon slot="activator" color="primary"
                      dark v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ $t('onboarding.companyForm.actualBeneficiaryDescription') }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <div v-if="errors.beneficiaries" class="red--text">
            {{ $t('onboarding.companyForm.missingActualBeneficiaryInfo') }}
          </div>

          <v-data-table
            :headers="beneficiariesHeaders"
            :items="company.beneficiaries"
            :items-per-page="-1"
            :no-data-text="$t('onboarding.companyForm.noBeneficiaries')"
            hide-default-footer
          >
            <template #[`item.country`]="{ item }">
              <BaseCountry :country="item.country "/>
            </template>
            <template #[`item.name`]="{ item }">
              <span v-if="item.company_name">{{ item.company_name }}</span>
              <span v-else>{{ item.first_name }} {{ item.last_name }}</span>
            </template>

            <template #[`item.idCode`]="{ item }">
              <span v-if="item.company_name">{{ item.registration_number }}</span>
              <span v-else>{{ item.national_id }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn icon @click="deleteBeneficiary(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-dialog v-model="item.beneficiaryPopUpEdit" persistent max-width="600px"
                        @keydown.esc="item.beneficiaryPopUpEdit = false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <BeneficiaryForm
                  v-if="item.beneficiaryPopUpEdit"
                  :items="company.beneficiaries"
                  :member="item"
                  :countries="registrationHints.country"
                  @close="item.beneficiaryPopUpEdit = false"
                  @success="updateBeneficiary"
                />
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-dialog v-model="beneficiaryPopUp" persistent max-width="600px"
                    @keydown.esc="beneficiaryPopUp = false"
          >
            <template #activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" v-text="$t('common.add')"/>
            </template>
            <BeneficiaryForm
              :items="company.beneficiaries"
              :countries="registrationHints.country"
              @close="beneficiaryPopUp = false"
              @success="addBeneficiary"
            />
          </v-dialog>
        </v-card-actions>
      </v-card>

      <v-btn
        large
        elevation="0"
        :loading="loading"
        dark
        @click="submit"
      >
        {{ $t('common.save') }}
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import BeneficiaryForm from "./BeneficiaryForm"
import ShareholderForm from "./ShareholderForm"
import BoardMemberForm from "./BoardMemberForm"

import Vue from 'vue'

export default Vue.extend({
  name: "CompanyForm",
  components: {
    BeneficiaryForm,
    ShareholderForm,
    BoardMemberForm,
  },
  props: {
    create: { type: Boolean, default: false },
  },
  emits: ["success"],
  data() {
    return {
      loading: false,
      loadingRegistrationHints: true,
      registrationHints: { country: [], activity: [] },
      company: {
        beneficiaries: [],
        shareholders: [],
        board_members: [],
        registry_extract_files: [],
        right_of_representation_files: [],
      },
      registryExtractFiles: [],
      rightOfRepresentationFiles: [],
      errors: {},
      beneficiariesHeaders: [
        { text: this.$t('onboarding.companyForm.name'),    align: 'start',   sortable: false, value: 'name'    },
        { text: this.$t('onboarding.companyForm.idCode'),  align: 'start',   sortable: false, value: 'idCode'  },
        { text: this.$t('onboarding.companyForm.country'), align: 'start',   sortable: false, value: 'country' },
        { text: this.$t('onboarding.companyForm.share'),   align: 'start',   sortable: false, value: 'share'   },
        { text: this.$t('onboarding.companyForm.actions'),                   sortable: false, value: 'actions' },
      ],
      shareholdersHeaders: [
        { text: this.$t('onboarding.companyForm.name'),    align: 'start',   sortable: false, value: 'name'    },
        { text: this.$t('onboarding.companyForm.idCode'),  align: 'start',   sortable: false, value: 'idCode'  },
        { text: this.$t('onboarding.companyForm.country'), align: 'start',   sortable: false, value: 'country' },
        { text: this.$t('onboarding.companyForm.share'),   align: 'start',   sortable: false, value: 'share'   },
        { text: this.$t('onboarding.companyForm.actions'),                   sortable: false, value: 'actions' },
      ],
      boardMembersHeaders: [
        { text: this.$t('onboarding.companyForm.firstName'),        align: 'start', sortable: false, value: 'first_name'    },
        { text: this.$t('onboarding.companyForm.lastName'),         align: 'start', sortable: false, value: 'last_name'     },
        { text: this.$t('onboarding.companyForm.nationalIdNumber'), align: 'start', sortable: false, value: 'national_id'   },
        { text: this.$t('onboarding.companyForm.country'),          align: 'start', sortable: false, value: 'country'       },
        { text: this.$t('onboarding.companyForm.canRepresent'),     align: 'start', sortable: false, value: 'can_represent' },
        { text: this.$t('onboarding.companyForm.actions'),                          sortable: false, value: 'actions'       },
      ],
      boardMembersPopUp: false,
      boardMembersPopUpEdit: false,
      shareholdersPopUp: false,
      shareholdersPopUpEdit: false,
      beneficiaryPopUp: false,
      beneficiaryPopUpEdit: false,
      skipBeneficiaries: true,
      includesRightOfRepresentation: null,
      emailRules: [
        (v) => !!v || this.$t('errors.emailIsRequired'),
        (v) =>
          new RegExp(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$", // `
          ).test(v) || this.$t('errors.notValidEmail'),
      ],
    }
  },
  computed: {
    investment_account() {
      return this.$store.state.account
    },
    economicActivities() {
      return this.registrationHints.activity.map(code => {
        return { code, name: this.$t(`economicActivities.${code}`) }
      })
    },
  },
  watch: {
    'investment_account.company': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fetch(newValue.id)
        }
      },
    },
  },
  mounted() {
    this.fetchRegistrationHints()
  },
  activated() {
    this.fetch(this.investment_account.company.id)
  },
  methods: {
    toFilename(file) {
      return file.file.split('/').pop().split('?')[0]
    },
    fetchRegistrationHints() {
      this.loadingRegistrationHints = true
      this.axios
        .get(`/auth/registration_hints/`)
        .then((response) => {
          this.loadingRegistrationHints = false
          this.registrationHints = response.data
        })
        .catch(() => {
          /* TODO so what do we do if this fails? */
          this.loadingRegistrationHints = false
        })
    },
    fetch(id) {
      if (!this.create) {
        this.$http.get(`/companies/${id}/`).then((result) => {
          this.company = result.data

          if (this.includesRightOfRepresentation === null) {
            this.includesRightOfRepresentation = this.company.right_of_representation_files.length === 0
          }
        })
      }
    },
    addBeneficiary(beneficiary) {
      if (!this.company.beneficiaries) {
        this.company.beneficiaries = []
      }
      if (this.errors.beneficiaries) {
        delete this.errors.beneficiaries
      }
      this.company.beneficiaries.push({ ...beneficiary })
      this.beneficiaryPopUp = false
    },
    deleteBeneficiary(item) {
      const index = this.company.beneficiaries.indexOf(item)
      if (index > -1) {
        this.company.beneficiaries.splice(index, 1)
      }
    },
    addBoardMember(boardMember) {
      if (!this.company.board_members) {
        this.company.board_members = []
      }
      if (this.errors.board_members) {
        delete this.errors.board_members
      }
      this.company.board_members.push({ ...boardMember })
      this.boardMembersPopUp = false
    },
    updateBoardMember(boardMember, oldBoardMember) {
      const index = this.company.board_members.indexOf(oldBoardMember)
      this.company.board_members.splice(index, 1, boardMember)
      boardMember.boardMembersPopUpEdit = false
    },
    updateShareholder(shareholder, oldShareholder) {
      const index = this.company.shareholders.indexOf(oldShareholder)
      this.company.shareholders.splice(index, 1, shareholder)
      shareholder.shareholdersPopUpEdit = false
    },
    updateBeneficiary(beneficiary, oldBeneficiary) {
      const index = this.company.beneficiaries.indexOf(oldBeneficiary)
      this.company.beneficiaries.splice(index, 1, beneficiary)
      beneficiary.beneficiaryPopUpEdit = false
    },
    deleteRegistryExtractFile(file) {
      this.company.registry_extract_files = this.company.registry_extract_files.filter(x => x !== file)
      this.$http.post(`registry_extract_files/${file.id}/delete_file/`)
    },
    deleteCompanyRightOfRepresentationFile(file) {
      this.company.right_of_representation_files = this.company.right_of_representation_files.filter(x => x !== file)
      this.$http.post(`right_of_representation_files/${file.id}/delete_file/`)
    },
    deleteBoardMember(item) {
      const index = this.company.board_members.indexOf(item)
      if (index > -1) {
        this.company.board_members.splice(index, 1)
      }
    },
    deleteShareholder(item) {
      const index = this.company.shareholders.indexOf(item)
      if (index > -1) {
        this.company.shareholders.splice(index, 1)
      }
    },
    addShareholder(shareholder) {
      if (!this.company.shareholders) {
        this.company.shareholders = []
      }
      if (this.errors.shareholders) {
        delete this.errors.shareholders
      }
      this.company.shareholders.push({ ...shareholder })
      this.shareholdersPopUp = false
    },
    submit() {
      this.errors = {}

      if (!this.company.shareholders.length) {
        this.errors.shareholders = true
      }

      if (!this.company.board_members.length) {
        this.errors.board_members = this.$t('errors.missingBoardMemberInfo')
      }

      if (!this.company.board_members.filter(item => item.can_represent || item.files_poa_detailed.length).length) {
        this.errors.board_members = this.$t('errors.atLeastOneBoardMemberShouldBeAbleToRepresent')
      }

      if (!this.skipBeneficiaries && !this.company.beneficiaries.length) {
        this.errors.beneficiaries = true
      }

      if (this.$refs.form.validate() && !Object.values(this.errors).length) {
        this.loading = true

        const investment_accounts = this.create ? [] : [ this.investment_account.id ]

        for (const board_member of this.company.board_members) {
          board_member.files     = board_member.files_detailed.map(file => file.id)
          board_member.files_poa = board_member.files_poa_detailed.map(file => file.id)
          board_member.registry_extract = board_member.registry_extract_detailed.map(file => file.id)
        }
        for (const shareholder of this.company.shareholders) {
          shareholder.files = shareholder.files_detailed.map(file => file.id)
        }
        for (const beneficiary of this.company.beneficiaries) {
          beneficiary.files = beneficiary.files_detailed.map(file => file.id)
        }

        let request = this.company.id
          ? this.$http.put(`/companies/${this.company.id}/`, this.company)
          : this.$http.post("/companies/", { ...this.company, investment_accounts })

        // Yes, the request above sends an array with one value. That's the result of the data model
        // we have. In theory, at least based on the models, one company can be related to multiple
        // investment accounts, and one person can have multiple individual accounts too. Yes,
        // business rules are likely to forbid that, but that just means we need validation (and not
        // a change in the data model). So, yeah, looks weird, but it's correct!

        request
          .then(response => {
            this.$auth.fetch().then(() => {
              let promise = Promise.all(this.registryExtractFiles.map(file => {
                const formData = new FormData()
                formData.append('file', file, file.name)
                formData.append('company', response.data.id)
                return this.$http.post('/registry_extract_files/', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
              })).then((responses) => {
                this.company.registry_extract_files = [ ...this.registryExtractFiles, ...responses.map(response => response.data) ]
              })

              let promisePOA = Promise.all(this.rightOfRepresentationFiles.map(file => {
                const formData = new FormData()
                formData.append('file', file, file.name)
                formData.append('company', response.data.id)
                return this.$http.post('/right_of_representation_files/', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
              })).then((responses) => {
                this.company.right_of_representation_files = [ ...this.rightOfRepresentationFiles, ...responses.map(response => response.data) ]
              })

              Promise.all([promise, promisePOA])
                .then(() => {
                  this.loading = false
                  this.registryExtractFiles = []
                  this.rightOfRepresentationFiles = []
                })
                .catch(() => {
                  this.loading = false
                })

              const account = this.$auth.user().investment_accounts.find(acc => acc.id === response.data.investment_accounts[0])
              this.$store.commit('setCurrentInvestmentAccount', account)
              this.$emit("next")
            })
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
  .main-container {
    .v-card__text {
      padding:20px;
    }
  }
</style>

