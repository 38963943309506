<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-text="$t('onboarding.beneficiaryForm.beneficiary')"/>
    </v-card-title>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-radio-group v-model="type" class="mt-0" row dense>
                <v-radio :label="$t('onboarding.beneficiaryForm.individual')" value="private"/>
                <v-radio :label="$t('onboarding.beneficiaryForm.company')" value="company"/>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-slide-x-transition hide-on-leave>
            <v-card v-if="type == 'private'" flat>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="beneficiary.first_name"
                    filled
                    required
                    :rules="namesRules"
                    :label="$t('onboarding.beneficiaryForm.firstName')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="beneficiary.last_name"
                    filled
                    required
                    :rules="namesRules"
                    :label="$t('onboarding.beneficiaryForm.lastName')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="beneficiary.national_id"
                    filled
                    required
                    :rules="nationalIdRules"
                    :label="$t('onboarding.beneficiaryForm.nationalIdNumber')"
                  />
                </v-col>
                <v-col cols="6">
                  <datepicker
                    v-model="beneficiary.birthdate"
                    filled
                    :label="$t('onboarding.beneficiaryForm.birthdate')"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="beneficiary.place_of_birth"
                    filled
                    :label="$t('onboarding.beneficiaryForm.placeOfBirth')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <BaseMultiselect
                    v-model="beneficiary.country"
                    filled
                    :items="countries"
                    :rules="[(v) => !!v || $t('errors.fieldRequired')]"
                    :label="$t('onboarding.beneficiaryForm.documentIssuingCountry')"
                    :multiple="false"
                    multi-select-data-type="country"
                    required
                  >
                    <template #selection="{ item }">
                      <span v-text="$t(`countries.${item}`)"/>
                    </template>
                    <template #item="{ item }">
                      <BaseCountry :country="item" no-tooltip/>
                      <span class="pl-4" v-text="$t(`countries.${item}`)"/>
                    </template>
                  </BaseMultiselect>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  {{ $t('onboarding.beneficiaryForm.politicallyExposedPerson') }}
                  <v-radio-group
                    v-model="beneficiary.is_exposed"
                    row
                    dense
                  >
                    <v-radio :label="$t('onboarding.beneficiaryForm.yes')" :value="true"/>
                    <v-radio :label="$t('onboarding.beneficiaryForm.no')" :value="false"/>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-x-transition>
          <v-slide-x-reverse-transition hide-on-leave>
            <v-card v-if="type == 'company'" flat>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="beneficiary.company_name"
                    filled
                    required
                    :rules="namesRules"
                    :label="$t('onboarding.beneficiaryForm.companyName')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="beneficiary.registration_number"
                    filled
                    required
                    :rules="numberRules"
                    :label="$t('onboarding.beneficiaryForm.registrationNumber')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <BaseMultiselect
                    v-model="beneficiary.country"
                    filled
                    :items="countries"
                    :rules="[(v) => !!v || $t('errors.fieldRequired')]"
                    :label="$t('onboarding.beneficiaryForm.country')"
                    :multiple="false"
                    multi-select-data-type="country"
                    required
                  >
                    <template #selection="{ item }">
                      <span v-text="$t(`countries.${item}`)"/>
                    </template>
                    <template #item="{ item }">
                      <BaseCountry :country="item" no-tooltip/>
                      <span class="pl-4" v-text="$t(`countries.${item}`)"/>
                    </template>
                  </BaseMultiselect>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-x-reverse-transition>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="beneficiary.share"
                filled
                required
                :rules="shareRule"
                :label="$t('onboarding.beneficiaryForm.sharePercent')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              {{ $t('onboarding.beneficiaryForm.sharesHeldDirectly') }}
              <v-radio-group
                v-model="sharesHeldDirectly"
                row
                dense
              >
                <v-radio :label="$t('onboarding.beneficiaryForm.yes')" :value="true"/>
                <v-radio :label="$t('onboarding.beneficiaryForm.no')" :value="false"/>
              </v-radio-group>
            </v-col>
            <v-col v-if="sharesHeldDirectly === false">
              <div v-for="company in beneficiary.holding_companies" :key="company.id">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="company.company_name"
                      filled
                      required
                      :label="$t('onboarding.beneficiariesForm.companyName')"
                    />
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="company.registration_number"
                      filled
                      required
                      :label="$t('onboarding.beneficiariesForm.registrationNumber')"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-btn v-if="beneficiary.holding_companies.length > 1" class="mt-3 ml-n2" icon @click="deleteHoldingCompany(company)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-btn icon @click="addHoldingCompany()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <template v-if="beneficiary.files_detailed.length">
                <div v-text="$t('onboarding.companyForm.existingFiles')"/>
                <ul class="mb-4">
                  <li v-for="file in beneficiary.files_detailed" :key="file.id">
                    <v-btn
                      color="lightgrey" :href="file.file" target="_blank" download
                      x-small class="mr-2 my-1 py-4"
                    >
                      <v-icon left>mdi-attachment</v-icon>
                      {{ toFilename(file) }}
                    </v-btn>
                    <v-btn x-small class="mr-2 my-1 py-4" text @click="beneficiary.files_detailed = beneficiary.files_detailed.filter(x => x !== file)">
                      <v-icon left>mdi-close</v-icon>
                      {{ $t('common.remove') }}
                    </v-btn>
                  </li>
                </ul>
              </template>
              <v-file-input
                v-model="files"
                filled
                multiple
                small-chips
                truncate-length="15"
                :rules="copyOfTheDocumentRules"
                :label="$t('onboarding.beneficiaryForm.uploadCopyOfTheDocument')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mx-5">
        <v-spacer/>
        <v-btn class="ma-3 pa-5" text :disabled="loading" @click="close()" v-text="$t('common.close')"/>
        <v-btn class="my-3 pa-5" dark :loading="loading" @click="submit()" v-text="$t('common.save')"/>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "BeneficiaryForm",
  props: {
    items: { type: Array, required: true },
    member: { type: Object, required: false, default: () => null },
    countries: { type: Array, required: true },
  },
  emits: ["success", "close"],
  data() {
    return {
      type: 'private',
      valid: true,
      loading: false,
      namesRules: [
        (v) => !!v || this.$t('errors.fieldRequired'),
        (v) => new RegExp("^([^0-9]*)$").test(v) || this.$t('errors.onlyLetters'),
      ],
      numberRules: [
        (v) => !!v || this.$t('errors.fieldRequired'),
        (v) => new RegExp("^\\d+$").test(v) || this.$t('errors.onlyNumbers'),
        (v) => (!!this.member || !this.items.filter(item => item.national_id == v).length) || this.$t('errors.thisBeneficiaryAlreadyExists'),
      ],
      nationalIdRules: [
        (v) => !!v || this.$t('errors.fieldRequired'),
        (v) => (!!this.member || !this.items.filter(item => item.national_id == v).length) || this.$t('errors.thisShareholderAlreadyExists'),
      ],
      copyOfTheDocumentRules: [
        (v) => (!!this.member || !!v.length) || this.$t('errors.fieldRequired'),
      ],
      shareRule: [
        (v) => !!v || this.$t('errors.youHaveToDefineSharePercent'),
        (v) => parseFloat(v) <= 100 || this.$t('errors.notCorrectValue'),
        (v) => parseFloat(v) > 0 || this.$t('errors.notCorrectValue'),
        (v) => new RegExp("^\\d+$").test(v) || this.$t('errors.onlyNumbers'),
      ],
      beneficiary: {
        first_name: "",
        last_name: "",
        company_name: "",
        national_id: "",
        birthdate: null,
        place_of_birth: null,
        share: 100,
        country: "",
        is_exposed: false,
        files: [],
        files_detailed: [],
        holding_companies: [{
          company_name: null,
          registration_number: null,
        }],
      },
      sharesHeldDirectly: null,
      files: [],
    }
  },
  created() {
    if (this.member) {
      this.beneficiary = { ...this.member }
    }
    if (this.member.holding_companies.length > 0) {
      this.sharesHeldDirectly = false
    }
  },
  methods: {
    toFilename(file) {
      return file.file.split('/').pop().split('?')[0]
    },
    reset() {
      this.beneficiary.files_detailed = []
      // Same as with clearing strategy form - reseting clears/nulls values,
      // but we want to return the to the initial state
      Object.assign(this.$data, this.$options.data.apply(this)) // resets data property with initial values
      this.$refs.form.resetValidation() // resets any validation errors
      Vue.nextTick().then(() => {
        this.type = 'private'
      })
    },
    close() {
      this.$emit("close")
      this.reset()
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        Promise.all(this.files.map(file => {
          const formData = new FormData()
          formData.append('file', file, file.name)
          return this.$http.post('/company_beneficiary_files/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        }))
          .then((responses) => {
            this.beneficiary.files_detailed = [ ...this.beneficiary.files_detailed, ...responses.map(response => response.data) ]

            this.loading = false
            this.dialog = false

            for (let holding_company of this.beneficiary.holding_companies) {
              if (this.sharesHeldDirectly || holding_company.company_name === '' && holding_company.registration_number === '') {
                this.deleteHoldingCompany(holding_company)
              }
            }

            const data = {...this.beneficiary}
            if (this.type == 'private') {
              delete data.company_name
              delete data.registration_number
            } else {
              delete data.first_name
              delete data.last_name
              delete data.national_id
            }

            if (this.beneficiary.birthdate === '') {
              delete this.beneficiary.birthdate
            }

            if (this.beneficiary.place_of_birth === '') {
              delete this.beneficiary.place_of_birth
            }

            this.$emit("success", data, this.member)
            this.reset()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    addHoldingCompany() {
      this.beneficiary.holding_companies.push({
        company_name: "",
        registration_number: "",
      })
    },
    deleteHoldingCompany(company) {
      const index = this.beneficiary.holding_companies.indexOf(company)
      this.beneficiary.holding_companies.splice(index, 1)
    },
  },
})
</script>
